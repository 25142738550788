<script>
import { mapActions } from 'vuex'
import managementMixin from '@/modules/Management/managementMixin'

export default {
  name: 'ManagementWebhooks',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datatable: () => import('@/components/general/Datatable'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    FilterList: () => import('@/components/general/FilterList'),
    Action: () => import('@/components/general/Action'),
    Tabs: () => import('@/components/general/Tabs')
  },

  mixins: [ managementMixin ],

  data () {
    return {
      webhooks: []
    }
  },

  computed: {
    canWrite () {
      return this.getContextPermission('webhooks') === 'write'
    }
  },

  created () {
    this.attemptListWebhooks()
      .then(({ data }) => {
        this.webhooks = data
      })
  },

  methods: {
    ...mapActions([ 'attemptListWebhooks' ]),

    createNewWebhook () {
      this.$router.push({ name: 'management.webhooks.edit' })
    },

    showWebhook (id) {
      this.$router.push({
        name: 'management.webhook',
        params: { id }
      })
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('management:header.title')"
      :description="$t('management:header.description')"
      background="/assets/images/themes/default/png/management_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="availableTabLink"
      />
    </ContentHeader>

    <div class="center-small">
      <FilterList>
        <Action
          slot="button"
          type="button"
          primary
          large
          fixed-width
          text="Novo Webhook"
          :disabled="!canWrite"
          @click="createNewWebhook"
        />
      </FilterList>

      <Datatable
        :items="webhooks"
      >
        <template slot="thead">
          <tr>
            <th class="th">
              Endereço
            </th>
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ props.item.name }}</span>
            </td>
            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  text="Visualizar"
                  @click="showWebhook(props.item.id)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>
